<template>
  <div>
    <v-dialog v-model="dialog" fullscreen>

      <v-card tile>

        <v-card-title>
          SMS
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-title>

          <div class="d-flex align-center">
            <v-text-field v-model="keyword"
                          :style="{width : '250px'}"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          hide-details
                          outlined placeholder="Recherche..."
                          single-line/>
          </div>

          <v-spacer/>

          <div class="d-flex text-no-wrap grey--text text--darken-1 font-weight-light fs-14">

            <div>
              <div v-if="isLoading">
                <v-skeleton-loader type="text" width="100"/>
              </div>
              <div v-else>
                <v-icon color="orange" small>mdi-timer-sand</v-icon>
                En attente <span class="font-weight-medium">[{{ stat.pending }}]</span></div>
            </div>

            <div class="px-10">
              <div v-if="isLoading">
                <v-skeleton-loader type="text" width="100"/>
              </div>
              <div v-else>
                <v-icon color="info" small>mdi-email-send</v-icon>
                Envoyé <span class="font-weight-medium">[{{ stat.sent }}]</span></div>
            </div>

            <div class="pr-10">
              <div v-if="isLoading">
                <v-skeleton-loader type="text" width="100"/>
              </div>
              <div v-else>
                <v-icon color="success" small>mdi-cellphone-message</v-icon>
                Reçu <span class="font-weight-medium">[{{ stat.received }}]</span></div>
            </div>

            <div>
              <div v-if="isLoading">
                <v-skeleton-loader type="text" width="100"/>
              </div>
              <div v-else>
                <v-icon color="error" small>mdi-alert</v-icon>
                Échoué <span class="font-weight-medium">[{{ stat.error }}]</span></div>
            </div>
          </div>

          <v-spacer/>

          <v-btn v-if="Object.keys(filter).length"
                 class="grey lighten-5 mr-2"
                 color="red"
                 text @click="[filter = {},getSms()]">
            <v-icon left>mdi-filter-off</v-icon>
            Filtre
          </v-btn>

          <v-btn color="secondary" text @click="$refs.filterSmsDialog.open()">
            <v-icon left>mdi-filter</v-icon>
            Filtre
          </v-btn>

          <v-btn color="secondary" icon @click="getSms">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

        </v-card-title>


        <v-divider class="mt-1"/>

        <v-card-text class="pa-5">

          <v-skeleton-loader v-if="isLoading" type="table"/>

          <div v-else>
            <div v-if="messages.length">
              <v-simple-table class="table-border rounded-lg">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Propriétaire</th>
                    <th class="text-center">Base de bonnées</th>
                    <th>N°Téléphone</th>
                    <th>Type</th>
                    <th>Sms</th>
                    <th>Coût</th>
                    <th>Statut</th>
                    <th>Date d'envoi</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in messages" :key="index">

                    <td>
                      <div :class="$func.containsArabic(item.salepoint.name) &&  'ar'"
                           class="d-flex text-no-wrap align-center py-2">

                        <v-avatar v-if="item.salepoint.image" size="38">
                          <v-img :src="$baseUrl + item.salepoint.image"></v-img>
                        </v-avatar>

                        <v-avatar v-else
                                  :class="$func.randomColor()"
                                  class="font-weight-medium"
                                  size="38">
                          {{ $func.avatarName(item.salepoint.name) }}
                        </v-avatar>

                        <span :class="$func.containsArabic(item.salepoint.name) ?  'mr-2' : 'ml-2'">
                          {{ item.salepoint.name }}
                          <span class="font-weight-medium red--text d-block">{{ item.salepoint.code }}</span>
                        </span>
                      </div>
                    </td>

                    <td class="text-center">
                      <div v-if="item.salepoint.database">
                        <v-chip :to="'/database/'+item.salepoint.database.id+'/show'"
                                class="bg-primary-subtle font-weight-medium"
                                small
                                text-color="secondary">
                          <v-icon color="secondary" left small>mdi-database</v-icon>
                          <div :style="{maxWidth: '100px'}"
                               class="d-inline-block text-truncate">
                            {{ item.salepoint.database.name }}
                          </div>
                          <v-icon color="secondary" right small>mdi-arrow-right</v-icon>
                        </v-chip>
                      </div>
                      <div v-else>
                        <v-icon color="red" dense>mdi-delete-empty</v-icon>
                        Supprimée
                      </div>
                    </td>

                    <td>
                      {{ item.phone }}
                    </td>

                    <td class="font-weight-medium">
                      {{ item.salepoint.salepoint_type ? item.salepoint.salepoint_type.name : '-' }}
                    </td>

                    <td>
                      <div :class="item.dir === 'rtl' ? 'ar' : ''" :dir="item.dir" class="py-2">
                        {{ item.sms }}
                      </div>
                    </td>

                    <td>
                      <span class="font-weight-medium red--text d-block text-no-wrap">
                        {{ item.sms_cost | toCurrency }} DZD
                      </span>
                    </td>

                    <td>
                      <div class="d-block text-no-wrap grey--text text--darken-1">
                        <v-icon :color="getStatus(item.status)['color']" small>
                          {{ getStatus(item.status)['icon'] }}
                        </v-icon>
                        {{ getStatus(item.status)['value'] }}
                      </div>
                    </td>

                    <td>
                       {{ item.sent_at ? $func.dateFormat(item.sent_at) : '-'}}
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
            <NoResult v-else/>
          </div>

        </v-card-text>

      </v-card>
    </v-dialog>

    <FilterSmsDialog ref="filterSmsDialog"
                     @search="[pagination.current = 1,filter = $event,getSms()]"/>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import FilterSmsDialog from "./FilterSmsDialog.vue";

export default {
    components: {FilterSmsDialog},
    data() {
        return {
            isLoading: false,
            dialog: false,
            campaign_id: '',
            messages: [],

            keyword: null,
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },
            filter: {},
            stat: {},
        }
    },
    methods: {
        open(campaignId) {
            this.dialog = true
            this.campaign_id = campaignId
            this.pagination.current = 1
            this.getSms()
        },
        getSms() {
            this.isLoading = true
            HTTP.get('/campaigns/sms?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    campaign_id: this.campaign_id,
                    per_page: this.per_page,
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false
                this.messages = res.data.data.data
                this.stat = res.data.stat

                this.pagination.current = res.data.data.current_page
                this.pagination.total = res.data.data.last_page
                this.total = res.data.data.total

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getSms();
        },
        getStatus(status) {
            switch (status) {
                case "sent":
                    return {
                        value: 'Envoyé',
                        icon: 'mdi-email-send',
                        color: 'info',
                    }

                case "error":
                    return {
                        value: 'Échoué',
                        icon: 'mdi-alert',
                        color: 'red',
                    }
                case "received":
                    return {
                        value: 'Reçu',
                        icon: 'mdi-cellphone-message',
                        color: 'success',
                    }
                default:
                    return {
                        value: 'En attente',
                        icon: 'mdi-timer-sand',
                        color: 'orange',
                    }
            }
        },

    },
    watch: {
        keyword() {
            this.getSms();
        },
        per_page() {
            this.pagination.current = 1
            this.getSms();
        },
    },
}
</script>

<style scoped>

</style>