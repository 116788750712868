<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="500">

      <v-card>

        <v-card-title>
          Détails
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <div class="font-weight-medium mb-2">
            <v-icon> mdi-cellphone-message</v-icon>
            SMS :
          </div>
          <div :class="campaign.dir === 'rtl' ? 'ar' : ''"
               :dir="campaign.dir"
               class="grey lighten-5 mb-8 rounded-lg pa-6">
            {{ campaign.sms }}
          </div>


          <div class="font-weight-medium mb-2">
            <v-icon>mdi-pencil-box-outline </v-icon>
            Description de la campagne :
          </div>
          <div class="grey lighten-5 rounded-lg pa-6">
            {{ campaign.description ? campaign.description : '-' }}
          </div>

        </v-card-text>

        <v-divider/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            campaign: {}
        }
    },
    methods: {
        open(campaign) {
            this.dialog = true
            this.campaign = campaign
        }
    }
}
</script>

<style scoped>

</style>