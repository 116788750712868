<template>
  <div>
    <v-row>

      <v-col cols="3">
        <v-card class="shadow rounded-lg">
          <v-card-text class="pa-6">
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-skeleton-loader v-if="isLoading" type="text" width="100"/>
                <span v-else
                      class="font-weight-medium d-block secondary--text fs-18 text-no-wrap">
                  {{ statistic.cost_total | toCurrency }} <span class="fs-12 red--text">DZD</span>
                </span>
                <p class="mt-1 mb-0">Coût total</p>
              </div>
              <v-spacer/>
              <v-avatar class="primary" size="45">
                <v-icon color="white" size="25">mdi-currency-usd</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card class="shadow rounded-lg">
          <v-card-text class="pa-6">
            <div class="d-flex align-center justify-space-between">
              <div>

                <v-skeleton-loader v-if="isLoading" type="text" width="100"/>
                <span v-else
                      class="font-weight-medium d-block secondary--text fs-18">
                  {{ statistic.total }}
                </span>

                <p class="mt-1 mb-0">Campagnes</p>
              </div>
              <v-spacer/>
              <v-avatar class="bg-grad-primary" size="45">
                <v-icon color="primary" size="25">mdi-file-document-outline</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card class="shadow rounded-lg">
          <v-card-text class="pa-6">
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-skeleton-loader v-if="isLoading" type="text" width="100"/>
                <span v-else class="font-weight-medium d-block secondary--text fs-18">
                  {{ statistic.sms_total }}
                </span>
                <p class="mt-1 mb-0">SMS envoyés</p>
              </div>
              <v-spacer/>
              <v-avatar class="bg-grad-primary" size="45">
                <v-icon color="primary" size="25">mdi-message-text-outline</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card class="shadow rounded-lg">
          <v-card-text class="pa-6">
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-skeleton-loader v-if="isLoading" type="text" width="100"/>
                <span v-else class="font-weight-medium d-block secondary--text fs-18">
                  {{ statistic.failed_sms_total }}
                </span>
                <p class="mt-1 mb-0">Échecs SMS</p>
              </div>
              <v-spacer/>
              <v-avatar class="bg-grad-primary" size="45">
                <v-icon color="primary" size="25">mdi-alert-octagon-outline</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>

<script>
export default {
    props: ['isLoading', 'statistic']
}
</script>

<style scoped>

</style>