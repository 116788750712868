<template>
  <div>
    <v-container fluid>

      <StatisticCards :is-loading="isLoading" :statistic="statistic"/>

      <v-row>
        <v-col cols="12">
          <v-card class="shadow rounded-lg">

            <Overlay :overlay="overlay"/>

            <v-card-title>

              <div class="d-flex align-center">
                <v-text-field v-model="keyword"
                              :style="{width : '250px'}"
                              append-icon="mdi-magnify"
                              clearable
                              dense
                              hide-details
                              outlined placeholder="Recherche..."
                              single-line/>
              </div>

              <v-spacer/>

              <v-btn v-if="Object.keys(filter).length"
                     class="grey lighten-5 mr-2"
                     color="red"
                     text @click="[filter = {},getCampaigns()]">
                <v-icon left>mdi-filter-off</v-icon>
             Filtre
              </v-btn>

              <v-btn class="mr-2" color="secondary" depressed exact to="/campaigns/create">
                <v-icon left>mdi-plus</v-icon>
                Campagne
              </v-btn>

              <v-btn color="secondary" text @click="$func.export('campaigns/export-excel')">
                <v-icon left>mdi-download</v-icon>
                Expoter
              </v-btn>

              <v-btn color="secondary" text @click="$refs.filterCampaignDialog.open()">
                <v-icon left>mdi-filter</v-icon>
                Filtre
              </v-btn>

              <v-btn color="secondary" icon @click="getCampaigns">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

            </v-card-title>


            <v-divider/>

            <v-card-text>

              <v-skeleton-loader v-if="isLoading" type="table"/>

              <div v-else>
                <div v-if="campaigns.length">
                  <v-simple-table class="table-border rounded-lg">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Statut</th>
                        <th>Titre</th>
                        <th class="text-center">Excel</th>
                        <th>Date d'envoi</th>
                        <th class="text-center">Bases de données</th>
                        <th class="text-center">Total SMS</th>
                        <th class="text-center">Coût Total</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,index) in campaigns" :key="index">
                        <td>
                          <v-switch v-model="item.is_active"
                                    class="pa-0 ma-0"
                                    color="success"
                                    hide-details
                                    readonly
                                    @click="changeStatus(item.id)"></v-switch>
                        </td>

                        <td>
                          {{ item.title }}
                        </td>

                        <td class="text-center">
                          <v-btn :disabled="!item.excel_file" color="secondary" icon @click="goTo(item.excel_file)">
                            <v-icon>mdi-microsoft-excel</v-icon>
                          </v-btn>
                        </td>

                        <td>
                          <div class="d-block text-no-wrap">
                            <v-icon color="primary" small>mdi-clock</v-icon>
                            {{ $func.dateFormat(item.scheduled_datetime) }}
                          </div>
                        </td>

                        <td class="text-center">
                          <v-chip :disabled="!item.databases.length"
                                  class="bg-primary-subtle font-weight-medium"
                                  small
                                  text-color="secondary"
                                  @click="$refs.databasesDialog.open(item.databases)">
                            <v-icon color="secondary" left small>mdi-database</v-icon>
                            {{ item.databases.length }} BDD
                            <v-icon color="secondary" right small>mdi-arrow-right</v-icon>
                          </v-chip>
                        </td>

                        <td class="text-center">
                          <v-chip class="font-weight-medium bg-success-subtle"
                                  small
                                  text-color="success"
                                  @click="$refs.smsListDialog.open(item.id)">
                            <v-icon color="success" left small>mdi-cellphone-message</v-icon>
                            {{ item.total_sms }}
                            <v-icon color="success" right small>mdi-arrow-right</v-icon>
                          </v-chip>
                        </td>

                        <td class="text-center">
                          <v-chip class="bg-primary-subtle font-weight-medium"
                                  small
                                  text-color="secondary">
                            {{ item.cost_sms | toCurrency }} DZD
                          </v-chip>
                        </td>

                        <td>

                          <div class="d-flex">

                            <v-tooltip bottom color="primary">
                              <template v-slot:activator="{ on }">
                                <v-btn :disabled="!item.error_sms_count"
                                       color="secondary"
                                       icon
                                       @click="restartJob(item.id)" v-on="on">
                                  <v-icon>mdi-backup-restore</v-icon>
                                </v-btn>
                              </template>
                              <span>Renvoyer</span>
                            </v-tooltip>

                            <v-tooltip bottom color="primary">
                              <template v-slot:activator="{ on }">
                                <v-btn color="secondary" icon @click="$refs.campaignDetailsDialog.open(item)" v-on="on">
                                  <v-icon>mdi-eye-plus-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Détails</span>
                            </v-tooltip>

                            <v-tooltip bottom color="primary">
                              <template v-slot:activator="{ on }">
                                <v-btn color="secondary" icon v-on="on">
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Modifier</span>
                            </v-tooltip>

                            <v-tooltip bottom color="primary">
                              <template v-slot:activator="{ on }">
                                <v-btn color="red" icon @click="deleteCampaign(item.id,index)" v-on="on">
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </template>
                              <span>Supprimer</span>
                            </v-tooltip>

                          </div>

                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-card-actions v-if="total > 10">

                    <div>
                      <v-select v-model="per_page"
                                :items="[10,20,50,100,200,300,400,500]"
                                :style="{width: '120px'}"
                                dense
                                hide-details
                                label="Ligne par page"
                                outlined></v-select>
                    </div>

                    <v-spacer/>
                    <v-pagination v-if="total>10"
                                  v-model="pagination.current"
                                  :length="pagination.total"

                                  total-visible="6"
                                  @input="onPageChange"
                    ></v-pagination>
                  </v-card-actions>

                </div>
                <NoResult v-else/>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <ConfirmDialog ref="confirmDialog"/>
      <DatabasesDialog ref="databasesDialog"/>
      <SmsListDialog ref="smsListDialog"/>
      <CampaignDetailsDialog ref="campaignDetailsDialog"/>
      <FilterCampaignDialog ref="filterCampaignDialog"
                            @search="[pagination.current = 1,filter = $event,getCampaigns()]"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import FilterCampaignDialog from "@/views/campaigns/components/FilterCampaignDialog.vue";
import DatabasesDialog from "@/views/campaigns/components/DatabasesDialog.vue";
import CampaignDetailsDialog from "@/views/campaigns/components/CampaignDetailsDialog.vue";
import SmsListDialog from "@/views/campaigns/components/SmsListDialog.vue";
import StatisticCards from "@/views/campaigns/components/StatisticCards.vue";

export default {
    components: {StatisticCards, FilterCampaignDialog, DatabasesDialog, CampaignDetailsDialog, SmsListDialog},
    data() {
        return {
            campaigns: [],
            overlay: false,
            isLoading: false,

            keyword: null,
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },
            filter: {},
            statistic: {
                total: 0,
                cost_total: 0,
                sms_total: 0,
                failed_sms_total: 0,
            },
        }
    },

    methods: {
        getCampaigns() {
            this.isLoading = true
            HTTP.get('/campaigns?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    per_page: this.per_page,
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false
                this.campaigns = res.data.data.data
                this.statistic = res.data.statistic

                this.pagination.current = res.data.data.current_page
                this.pagination.total = res.data.data.last_page
                this.total = res.data.data.total

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        async deleteCampaign(id, index) {
            if (await this.$refs.confirmDialog.open('', 'Vous ne pourrez pas revenir en arrière !', {
                icon: 'mdi-delete-empty-outline',
                agreeText: 'Supprimer',
                color: 'red',
            })) {
                this.overlay = true
                HTTP.delete('campaigns/' + id + '/delete').then(() => {
                    this.campaigns.splice(index, 1)
                    this.overlay = false
                    this.$successMessage = 'Cet élément a été supprimé avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        async changeStatus(campaignId) {
            if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir changer le statut de cette campagne ?', {
                icon: 'mdi-backup-restore',
                agreeText: 'Changer',
                color: 'red',
            })) {
                this.overlay = true
                HTTP.post('/campaigns/' + campaignId + '/change-status').then(() => {
                    this.overlay = false
                    this.getCampaigns()
                    this.$successMessage = 'Cette campagne a été modifiée avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        async restartJob(campaignId) {
            if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de renvoyer tous les messages non envoyés(Échecs) dans cette campagne ?', {
                icon: 'mdi-backup-restore',
                agreeText: 'Renvoyer',
                color: 'warning',
            })) {
                this.overlay = true
                HTTP.post('/campaigns/' + campaignId + '/restart-job').then(() => {
                    this.overlay = false
                    this.$successMessage = 'Cette opération a été enregistrée avec succès.'
                    this.getCampaigns()
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        onPageChange() {
            this.getCampaigns();
        },
        goTo(file) {
            window.open(process.env.VUE_APP_BASE_URL + file, '_blanc')
        }
    },
    created() {
        this.getCampaigns()
    },
    watch: {
        keyword() {
            this.getCampaigns();
        },
        per_page() {
            this.pagination.current = 1
            this.getCampaigns();
        },
    },
}
</script>

<style scoped>

</style>