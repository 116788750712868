<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="400">

      <v-card>

        <v-card-title>
          Bases de données
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4 text-center">
          <div v-for="(database,i) in databases" :key="i" class="py-1">
            <v-chip :to="'/database/'+database.id+'/show'"
                    class="bg-primary-subtle font-weight-medium"
                    small
                    text-color="secondary">
              <v-icon color="secondary" left small>mdi-database</v-icon>
              {{ database.name }}
              <v-icon color="secondary" right small>mdi-arrow-right</v-icon>
            </v-chip>
          </div>
        </v-card-text>

        <v-divider/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            databases: []
        }
    },
    methods: {
        open(databases) {
            this.dialog = true
            this.databases = databases
        }
    }
}
</script>

<style scoped>

</style>